"use client";

import { useAuth } from "@/context/AuthUserProvider";
import Link from "next/link";
import { useRouter } from "next/navigation";

function IndexPage() {
  const { authUser } = useAuth();
  const uid = authUser?.uid;
  const router = useRouter();
  if (uid) {
    router.push("/home");
  }
  return (
    <div className="w-screen h-screen bg-black">
      <div className="absolute top-14 right-14">
        <h1 className="text-white ">
          {" "}
          <a
            href="mailto:hello@audiovisualmechanism.com?subject=WAITING%20LIST&body=I%20would%20like%20to%20receive%20updates%20and%20notification%20on%20progress"
            className=" text-xs text-white tracking-widest"
          >
            [ waiting list ]
          </a>
        </h1>
      </div>
      <div className="absolute bottom-14 right-14">
        <h1 className="text-white ">
          {" "}
          <Link href="/login">+</Link>
        </h1>
      </div>
    </div>
  );
}

export default IndexPage;
